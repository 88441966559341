#reelsContainer {
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  position: relative;
  scroll-snap-type: y mandatory;
}

.reel {
  width: 100%;
  height: 100vh;
  object-fit: cover;
  scroll-snap-align: start;
}
